import axios from "axios"
import moment from "moment"
import React from "react"
import { navigate } from "gatsby"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import queries from "../../queries/bookingform"

import "../../styles/checkout.css"

class CheckoutPage extends React.Component {
  GRAPHQL_API = process.env.GATSBY_PIH_URL
  RETURN_URL_BASE = process.env.GATSBY_RETURN_URL_BASE

  // Default payment mode
  state = {
    PayBy: "",
    paymentSessionID: "",
    cart: null,
  }

  constructor(props) {
    super(props)
    this.bindMethods()
    this.setPropsFromLocalStorage()
  }

  bindMethods() {
    this.formChanged = this.formChanged.bind(this)
  }

  setPropsFromLocalStorage() {
    this.token =
      typeof window !== "undefined" && window.localStorage.getItem("token")
    this.tourCode =
      typeof window !== "undefined" && window.localStorage.getItem("tourCode")
    this.tourDate =
      typeof window !== "undefined" && window.localStorage.getItem("tourDate")
    this.bookingRef =
      typeof window !== "undefined" && window.localStorage.getItem("bookingRef")
    this.expiresAt =
      typeof window !== "undefined" && window.localStorage.getItem("expiresAt")
    this.paymentSessionID =
      typeof window !== "undefined" &&
      window.localStorage.getItem("paymentSessionID")
  }

  async componentDidMount() {
    if (moment(this.expiresAt) <= moment()) {
      typeof window !== "undefined" && window.localStorage.clear()
      navigate("/cart/")
      return
    }

    const token =
      typeof window !== "undefined" && window.localStorage.getItem("token")
    const bookingRef =
      typeof window !== "undefined" && window.localStorage.getItem("bookingRef")

    let query = queries.getCart(bookingRef)

    const cartResult = await axios
      .post(
        this.GRAPHQL_API,
        { query },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(resp => resp)

    if (cartResult.status !== 200 || cartResult.data.errors) {
      // TODO: Display cartResult.data.errors somehow, maybe redirect on expired
      console.log("cartResult error", cartResult.data.errors)
      return
    }

    console.log(cartResult.data.data.getCart)

    this.setState({ cart: cartResult.data.data.getCart })

    let cost = cartResult.data.data.getCart.cost

    query = queries.getPaymentSessionID(
      bookingRef,
      cost,
      `${this.RETURN_URL_BASE}/cart/payment?bookingRef=${bookingRef}`
    )

    const paymentResult = await axios
      .post(
        this.GRAPHQL_API,
        { query },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(resp => resp)

    if (paymentResult.status !== 200 || paymentResult.data.errors) {
      // TODO: Display cartResult.data.errors somehow, maybe redirect on expired
      console.log("paymentResult error", paymentResult.data.errors)
      return
    }

    this.paymentSessionID = paymentResult.data.data.getPaymentSessionID
    typeof window !== "undefined" &&
      window.localStorage.setItem("paymentSessionID", this.paymentSessionID)

    this.setState({ paymentSessionID: this.paymentSessionID })

    console.log("paymentResult", paymentResult)
  }

  formChanged(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    return (
      <>
        <SEO title="Checkout | New Zealand Bus Tours - awesomeNZ" />
        <Layout
          layoutType="typography cart CartDetailsPage"
          slideshow={
            <ul class="slideshow-slides">
              <li class="slideshow-slide first">
                <img
                  src={require("../../images/slideshow/hobbit-hole.jpg")}
                  alt=""
                  class="first last image"
                />
              </li>
            </ul>
          }
          hasSliderHandles={false}
          hasSliderBlurSmall={true}
          content={
            <div id="row" class="fourteen clearfix">
              <div class="title-div clearfix">
                <h1>Secure Checkout - Payment and Confirmation</h1>
                <h3>One last step and you're good to go!</h3>
              </div>
              <div id="Overview" class="clearfix">
                <div id="Content" class="clearfix">
                  <div id="ContentTab">
                    {/* Content from awesomeNZ tour page goes here */}
                    <div className="details clearfix">
                      <div className="bookingSummarySection">
                        <h2 className="tour">You are about to purchase,</h2>
                        <div
                          className="bookingSummary Tour AW Holding "
                          id="IH304574823"
                        >
                          <span className="Description">
                            {/* TODO Loop over tours, not just first item */}
                            The {
                              this.state.cart?.cartItems[0]?.tour?.title
                            } on {this.state.cart?.cartItems[0]?.departureDate}
                            {/* time{}*/}
                          </span>
                          <span className="Composition">
                            {" "}
                            for{" "}
                            {this.state.cart?.cartItems[0]?.numAdults +
                              this.state.cart?.cartItems[0]?.numChildren +
                              this.state.cart?.cartItems[0]?.numInfants}{" "}
                            passengers
                          </span>
                        </div>
                        {/*
          <div
            className="bookingSummary Sector AW Holding TourComp IH304574823"
            id="IH304574824"
          >
            <span className="Description">
              The Tour: Queenstown - Milford Sound on 20-Mar-2020 7:05 am
            </span>
            <span className="Composition">for 1 passenger</span>
          </div>
          <div className="bookingSummary Extra  Holding " id="IH304574833">
            <span className="Description">
              The Air Milford Scenic Flight - Milford Sound to Queenstown on
            </span>
            <span className="Composition">for 1 passenger</span>
          </div>
          <div
            className="bookingSummary Extra  Holding TourComp IH304574823"
            id="IH304574826"
          >
            <span className="Description">The Miss Lucy's Vista Menu on</span>
            <span className="Composition">for 1 passenger</span>
          </div>
          <div
            className="bookingSummary Extra  Holding TourComp IH304574823"
            id="IH304574825"
          >
            <span className="Description">The Maiden of Milford Cruise on</span>
            <span className="Composition">for 1 passenger</span>
          </div>
          <div
            className="bookingSummary Sector AW Holding TourComp IH304574823"
            id="IH304574827"
          >
            <span className="Description">
              The Tour: Milford Sound - Queenstown on 20-Mar-2020 3:00 pm
            </span>
            <span className="Composition">for 1 passenger</span>
          </div>
          */}
                      </div>
                      <div className="Confirmation clearfix">
                        <form
                          id="Form_PaymentForm"
                          action="https://uat.windcave.com/pxmi3/pxfusionauth"
                          method="post"
                          encType="application/x-www-form-urlencoded"
                          onChange={event => this.formChanged(event)}
                        >
                          <p
                            id="Form_PaymentForm_error"
                            className="message "
                            style={{ display: "block" }}
                          />
                          <fieldset>
                            <div
                              className="field fieldgroup h5fieldset PayFormHead nolabel"
                              id="PayFormHeader"
                            >
                              <div className="fieldgroupField Value">
                                <div className="fieldholder-small">
                                  <label
                                    className="fieldholder-small-label"
                                    htmlFor="Form_PaymentForm_Value"
                                  >
                                    Amount:&nbsp;
                                  </label>
                                  <span
                                    id="Form_PaymentForm_Value"
                                    className="readonly"
                                  >
                                    ${this.state.cart?.cost}
                                  </span>
                                  {/*<input
                      type="hidden"
                      name="Value"
                      defaultValue={this.state.cart?.cartItems[0]?.cost}
                      className="readonly"
                      id="Form_PaymentForm_Value"
                    />*/}
                                </div>
                              </div>
                              <div className="fieldgroupField PayBy">
                                <div className="fieldholder-small">
                                  <label
                                    className="fieldholder-small-label"
                                    htmlFor="Form_PaymentForm_PayBy"
                                  >
                                    Payment Method
                                  </label>
                                  <select
                                    name="PayBy"
                                    className="dropdown"
                                    id="Form_PaymentForm_PayBy"
                                    onChange={event => this.formChanged(event)}
                                  >
                                    <option value="">
                                      Select Payment Method
                                    </option>
                                    <option value="CC">Credit Card</option>
                                    {/*
                      <option value="Poli">
                        Internet Banking (Pay with POLi)
                      </option>
                      <option value="NA">
                        Pay using Rewards or a Pass
                      </option>
                      */}
                                  </select>
                                </div>
                              </div>
                              <div className="fieldgroupField NoPointsValue">
                                <div className="fieldholder-small">
                                  <input
                                    type="hidden"
                                    name="NoPointsValue"
                                    defaultValue={
                                      this.state.cart?.cartItems[0]?.cost
                                    }
                                    className="hidden nolabel"
                                    id="Form_PaymentForm_NoPointsValue"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="fieldgroupField UsePointsValue">
                                <div className="fieldholder-small">
                                  <input
                                    type="hidden"
                                    name="UsePointsValue"
                                    defaultValue={
                                      this.state.cart?.cartItems[0]?.cost
                                    }
                                    className="hidden nolabel"
                                    id="Form_PaymentForm_UsePointsValue"
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>

                            {this.state?.PayBy === "CC" ? (
                              <div
                                className="field fieldgroup h5fieldset PayByCC nolabel"
                                id="PayFormCCFields"
                                style={{ display: "block" }}
                              >
                                <div className="fieldgroupField CCInfo">
                                  <p id="cvc_help_link">What is this?</p>
                                  <div id="cvc_help" className="hidden">
                                    <h2>
                                      What is the Card Verification Code (CVC)
                                      code? <span className="closeme">X</span>
                                    </h2>
                                    <p>
                                      <img
                                        src="assets/intercity12/main/creditcard_examples3.gif"
                                        alt="locate CVC"
                                      />
                                    </p>
                                    <p>
                                      For Visa, Discover and MasterCard the
                                      CVV2/CVC2 is a three digit security code
                                      that is printed on the back of the cards.
                                      The number appears in reverse italic at
                                      the top of the signature panel at the end.
                                      For American Express cards, the CID is a
                                      four digit number on the front of the
                                      card.
                                    </p>
                                  </div>
                                </div>
                                {/*
                <div className="fieldgroupField CardType">
                  <div className="fieldholder-small">
                    <ul
                      id="Form_PaymentForm_CardType"
                      className="optionset nolabel"
                    >
                      <li className="odd valVisa">
                        <input
                          id="Form_PaymentForm_CardType_Visa"
                          className="radio"
                          name="CardType"
                          type="radio"
                          defaultValue="Visa"
                        />
                        <label htmlFor="Form_PaymentForm_CardType_Visa">
                          Visa
                        </label>
                      </li>
                      <li className="even valMC">
                        <input
                          id="Form_PaymentForm_CardType_MC"
                          className="radio"
                          name="CardType"
                          type="radio"
                          defaultValue="MC"
                        />
                        <label htmlFor="Form_PaymentForm_CardType_MC">
                          MasterCard
                        </label>
                      </li>
                      <li className="odd valAmex">
                        <input
                          id="Form_PaymentForm_CardType_Amex"
                          className="radio"
                          name="CardType"
                          type="radio"
                          defaultValue="Amex"
                        />
                        <label htmlFor="Form_PaymentForm_CardType_Amex">
                          American Express
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
                */}
                                <input
                                  type="hidden"
                                  name="SessionId"
                                  value={this.state.paymentSessionID}
                                />

                                <div className="fieldgroupField CCNum">
                                  <div className="CCNum leftlabel">
                                    <label
                                      className="leftlabel"
                                      htmlFor="Form_PaymentForm_CCNum"
                                    >
                                      Card Number
                                    </label>
                                    <span
                                      id="CCNum_Holder"
                                      className="CreditCardNumber"
                                    >
                                      <input
                                        type="text"
                                        className="text CardName"
                                        id="Form_PaymentForm_CCNum"
                                        name="CardNumber"
                                        defaultValue=""
                                        autoComplete="off"
                                        maxLength={19}
                                        pattern="[0-9]*"
                                        title="Numeric credit card number, with no punctuation."
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className="fieldgroupField Name">
                                  <div className="Name leftlabel">
                                    <label
                                      className="leftlabel"
                                      htmlFor="Form_PaymentForm_Name"
                                    >
                                      Card Holder Name
                                    </label>
                                    <input
                                      type="text"
                                      className="text CardName"
                                      id="Form_PaymentForm_Name"
                                      name="CardHolderName"
                                      defaultValue=""
                                      autoComplete="on"
                                    />
                                  </div>
                                </div>
                                <div className="fieldgroupField CCExp">
                                  <div className="CCExp leftlabel">
                                    <label
                                      className="leftlabel"
                                      htmlFor="Form_PaymentForm_CCExp"
                                    >
                                      Card Expiry
                                    </label>
                                    <span
                                      id="CCExp_Holder"
                                      className="CCExpiry"
                                    >
                                      <input
                                        type="text"
                                        className="text CCExpiry CCExpMonth"
                                        id="Form_PaymentForm_CCExp"
                                        name="ExpiryMonth"
                                        defaultValue=""
                                        autoComplete="off"
                                        maxLength={2}
                                        min={1}
                                        max={12}
                                        placeholder="MM"
                                        title="Card Expiry Month (2 digits) e.g. 03 for March"
                                        pattern="(0[1-9]|1[012])"
                                      />{" "}
                                      /{" "}
                                      <input
                                        type="text"
                                        className="text CCExpiry CCExpYear"
                                        id="Form_PaymentForm_CCExp_YY"
                                        name="ExpiryYear"
                                        defaultValue=""
                                        autoComplete="off"
                                        maxLength={2}
                                        min={1}
                                        max={99}
                                        placeholder="YY"
                                        title="Card Expiry Year (last 2 digits of year)"
                                        pattern="([12345][0-9])"
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className="fieldgroupField CVC">
                                  <div className="CVC leftlabel">
                                    <label
                                      className="leftlabel"
                                      htmlFor="Form_PaymentForm_CVC"
                                    >
                                      CVV/CVC
                                    </label>
                                    <input
                                      type="text"
                                      className="text CardCVC"
                                      id="Form_PaymentForm_CVC"
                                      name="Cvc2"
                                      defaultValue=""
                                      autoComplete="off"
                                      title="Card Verification Code"
                                      pattern="[0-9]*"
                                      maxLength={4}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {/*
                this.state?.PayBy === 'Poli' ?
                <div
                className="field fieldgroup h5fieldset PayByPoli nolabel"
                id="PayFormPoliFields"
                style={{ display: "block" }}
              >
                <div className="fieldgroupField PoliInfo">
                  <p>
                    <img
                      className="right"
                      src="assets/reservations/poli.png"
                      alt="Internet Banking (Pay with POLi)"
                      width={98}
                      height={94}
                    />
                    POLi is a secure online payment option you can use to safely
                    pay for your tickets directly from your bank account using
                    internet banking.
                  </p>
                  <p>
                    Check the list of{" "}
                    <a
                      rel="external"
                      href="https://transaction.apac.paywithpoli.com/POLiFISupported.aspx?merchantcode=6400052"
                    >
                      available banks
                    </a>
                    <br />
                    <a
                      rel="external"
                      href="http://www.polipayments.com/consumer.aspx"
                    >
                      Learn more about POLi
                    </a>
                  </p>
                  <p>
                    Please accept the Terms and Conditions and click on{" "}
                    <em>Buy Now</em> below then follow the instructions to process
                    your payment.
                  </p>
                  <p>&nbsp;</p>
                </div>
              </div> : ''
              }
              {
                this.state?.PayBy === 'NA' ?
                <div
                className="field fieldgroup h5fieldset PayByNA nolabel"
                id="PayFormNopayFields"
                style={{ display: "block" }}
              >
                <div className="fieldgroupField NopayInfo">
                  <p>
                    <span
                      style={{
                        color: "#222222",
                        fontFamily: "arial, sans-serif",
                        fontSize: 12,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: "normal",
                        letterSpacing: "normal",
                        lineHeight: 16,
                        orphans: "auto",
                        textAlign: "start",
                        textIndent: 0,
                        textTransform: "none",
                        whiteSpace: "normal",
                        widows: "auto",
                        wordSpacing: 0,
                        WebkitTextSizeAdjust: "auto",
                        WebkitTextStrokeWidth: 0,
                        display: "inline !important",
                        float: "none",
                      }}
                    >
                      This option applies when fares are purchased on a pass or
                      using existing credit. Please continue to checkout to
                      receive ticket confirmation.
                    </span>
                  </p>
                </div>
              </div>: ''
              */}
                            <div
                              className="field fieldgroup h5fieldset ConfirmTerms nolabel"
                              id="PayFormTerms"
                            >
                              <div className="fieldgroupField TermsHeading">
                                <h4 className="SubTitle">
                                  Terms and Conditions
                                </h4>
                              </div>
                              <div className="fieldgroupField Term_AirMilfordScenicFlight-MilfordSoundtoQueenstown">
                                <label>
                                  Air Milford Scenic Flight - Milford Sound to
                                  Queenstown
                                </label>
                                <span>
                                  From 1st October: 0 - 1 (Infant) 2 - 12
                                  (Child) 13+ ( Adult)
                                </span>
                              </div>
                              <div className="fieldgroupField Term_FlexiFare">
                                <label>Flexi Fare</label>
                                <span>
                                  &gt; Bus and ferry services can be modified or
                                  cancelled up to 2 hours before departure with
                                  100% refund. Train services can be modified or
                                  cancelled up to 48 hours prior to travel with
                                  100% refund. &gt; After the above stated
                                  times, modifications are not permitted and if
                                  you cancel there will be no refund. &gt; If
                                  you have upgraded from a Standard fare to a
                                  Flexi fare and cancel before the above stated
                                  time, only the upgraded portion is refundable.
                                  There is no refund if you cancel after the
                                  above stated time. &gt; If you modify your
                                  booking online or via our contact centre,
                                  booking and amendment fees apply. Some agents
                                  also charge additional fees.
                                </span>
                              </div>
                              <div className="fieldgroupField StandardTerms" />
                            </div>
                            <div
                              className="field fieldgroup h5fieldset nolabel"
                              id="PayFormAccept"
                            >
                              <div className="fieldgroupField AcceptTerms">
                                <div className="AcceptTerms rightlabel">
                                  {/*name="AcceptTerms"*/}
                                  <input
                                    type="checkbox"
                                    className="text"
                                    id="Form_PaymentForm_AcceptTerms"
                                    defaultValue
                                    autoComplete="on"
                                    required="required"
                                    title="You must accept the Terms and Conditions to complete your booking."
                                  />
                                  <label
                                    className="rightlabel"
                                    htmlFor="Form_PaymentForm_AcceptTerms"
                                  >
                                    I accept the Terms and Conditions of Travel
                                    and the terms of the fare types I have
                                    selected
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="clear"></div>
                          </fieldset>
                          <div className="Actions">
                            {/*name="action_ConfirmAndPay"
              <input type="hidden" name="IssNum" />
              <input type="hidden" name="UserTxnData1" />
              <input type="hidden" name="UserTxnData2" />
              <input type="hidden" name="UserTxnData3" />*/}

                            <input type="hidden" name="Action" value="Add" />
                            <input
                              type="hidden"
                              name="Object"
                              value="DpsPxPay"
                            />
                            <input
                              type="submit"
                              value="Buy Now"
                              className="action"
                              id="Form_PaymentForm_action_ConfirmAndPay"
                            />
                          </div>
                        </form>
                      </div>
                      <div id="Content" className="typography">
                        <div className="clear" />
                      </div>
                      <div className="typography Footer"></div>
                    </div>
                    {/* ... */}
                    <div class="clear"></div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </>
    )
  }
}

export default CheckoutPage
